var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"role":"main","data-id":"meeting documents"}},[_c('div',{style:({ padding: '8px' })},[_c('b-button',{staticClass:"is-primary is-rounded",on:{"click":_vm.openAddModal}},[_vm._v("Add Document")]),_c('span',{style:({ display: 'inline-block', width: '10px' })}),_c('b-button',{staticClass:"is-primary is-rounded",on:{"click":_vm.preview}},[_vm._v("Preview Document")]),_c('div',{style:({
        float: 'right',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
      })},[_c('div',{style:({
          position: 'relative',
          paddingRight: '6px'
        })},[_c('b-icon',{style:({
            animation: 'spinAround .5s infinite linear',
            opacity: _vm.status === 'saving' ? 1 : 0
          }),attrs:{"icon":"loading","size":"is-small"}})],1),(_vm.status === 'saved')?_c('div',{style:({
          background: '#ddd',
          padding: '2px 0px',
          width: '60px',
          textAlign: 'center',
          borderRadius: '4px'
        })},[_vm._v(" saved ")]):_vm._e(),(_vm.status === 'saving')?_c('div',{style:({
          background: '#ddd',
          padding: '2px 0px',
          width: '60px',
          textAlign: 'center',
          borderRadius: '4px'
        })},[_vm._v(" saving ")]):_vm._e()])],1),_c('ModernTable',{ref:"table",attrs:{"columns":_vm.columns,"rows":_vm.rows,"filters":{
      show: false
    },"loading":_vm.loading}}),_c('Modal',{ref:"modal",attrs:{"afterUpload":_vm.afterUpload}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }