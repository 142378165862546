/*
  example:
    // note: calls notifyError internally
  
    import { storeBPD } from '@/services/BoardMeetings/BoardPackageDocument/StoreBoardPackageDocumentFile';

    const { documentID, successful, message } = await storeBPD ({
      doc,
      params: {
				boardPackageDocumentID
      }
    });
    if (!successful) {
      notifyProblem (message);
      return;
    }
*/
//
import kms from '@/services/kms'
import { notifyError } from '@/services/notify'
//
export async function storeBPD({ doc, params }) {
  try {
    const formData = new FormData()
    formData.append('file', doc)

    const path = `/BoardMeetings/BoardPackageDocument/StoreBoardPackageDocumentFile?boardPackageDocumentID=${params.boardPackageDocumentID}`

    const result = await kms.post(path, formData, {
      'Content-Type': 'multipart/form-data'
    })

    return {
      successful: true,
      message: '',
      documentID: result.documentId,
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  let message = `The board pacakge document could not be added.`
  notifyError(message)

  return {
    successful: false,
    message,
    result: null,
    documentID: null
  }
}

///////
