import _get from 'lodash/get'

export async function open({
  mode,
  boardPackageID,
  boardPackageDocumentID,
  documentName = '',
  sources,
  spot,
  list = []
}) {
  this.loading = true
  this.toggle = true

  this.mode = mode
  this.boardPackageID = boardPackageID

  this.documents = []
  this.sources = sources
  this.documentDescription = documentName

  if (mode === 'edit') {
    this.boardPackageDocumentID = boardPackageDocumentID
    this.spot = spot
    this.documentType = _get(spot, 'layoutDocumentSource', null)

    const layoutDocumentSourceID = _get(
      spot,
      ['layoutDocumentSource', 'layoutDocumentSourceID'],
      null
    )

    if (layoutDocumentSourceID === 3) {
      await this.getDocument({
        boardPackageDocumentID
      })

      this.$refs.pdfDisplay.open({
        src: this.documents[0]
      })
    }
  } else if (mode === 'add') {
    this.nextOrderIndex = list.length
  }

  this.loading = false
}
