/*
	import { eraseBoardPackageDocument } from '@/services/BoardMeetings/BoardPackageDocument/Delete';

  await eraseBoardPackageDocument ({
    json: {
      "hoaID": hoaID,
      boardPackageID: 45,

      // "documentID": 0,
      "layoutDocumentSourceID": 2,
      "layoutAvailabilityID": 1,
      // "sortOrder": 0,
      // "documentName": "string",

      "documentName": "name of the document",  
      
    }
  }).then (({ successful, message }) => {
    if (!successful) {
      throw message;
    }
  });
*/

/*
  exception.fields {
    phoneNumber: [ '' ],    
  }
*/
//
//
//
import kms from '@/services/kms'
//
import { notifyError } from '@/services/notify'
//
export async function eraseBoardPackageDocument({ boardPackageDocumentID }) {
  let exception = ''

  try {
    const result = await kms.delete(
      `/BoardMeetings/BoardPackageDocument/${boardPackageDocumentID}?forceDelete=false`
    )

    return {
      successful: true,
      message: 'The board meeting document was deleted successfully.',
      exception: null,
      boardPackageDocument: result
    }
  } catch (_exception) {
    exception = _exception
  }

  let message = 'The board meeting document could not be deleted.'
  notifyError(message)

  return {
    successful: false,
    message,
    exception,
    boardPackageDocument: null
  }
}
