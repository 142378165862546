//

import { notifyError } from '@/services/notify'
import { listBoardPackageDocuments } from '@/services/BoardMeetings/BoardPackageDocument/List'
import { eraseBoardPackageDocument } from '@/services/BoardMeetings/BoardPackageDocument/Delete'
import { listLayoutDocumentSources } from '@/services/BoardMeetings/LayoutDocumentSource/List'
import { updateBoardPackageDocument } from '@/services/BoardMeetings/BoardPackageDocument/Put'
import { renderBPD } from '@/services/BoardMeetings/BoardPackage/RenderBoardPackageDocument'
import { base64toBlob, showPdf } from '@/services/blobutility'

import { onSort } from './list/onSort'
import { parseList } from './list/parseList'

import _cloneDeep from 'lodash/cloneDeep'

export const methods = {
  async refresh() {
    this.loading = true

    await this.getSources()
    await this.getList()
    await this.parseList()

    this.loading = false
  },

  async preview() {
    const { sequence, successful, message } = await renderBPD({
      params: {
        boardPackageID: this.boardPackageID,
        mergeFileName: 'Documents',
        asBase64: true
      }
    })
    if (!successful) {
      console.error(message)
      return
    }

    const blob = base64toBlob(sequence.split('data:application/pdf;base64,')[1], 'application/pdf')
    showPdf(blob)
  },

  async getSources() {
    const hoaID = this.hoaId

    const { sources, successful, message } = await listLayoutDocumentSources({
      params: {
        hoaID
      }
    })
    if (!successful) {
      console.error(message)
      return
    }

    this.sources = sources
  },

  /*
    route that takes: {
      boardPackageID
      boardPackageDocumentIDs: [] 
    }
  */
  async update() {
    this.status = 'saving'
    clearTimeout(this.savingTimeout)

    const promises = this.list.map(_entry => {
      const entry = _cloneDeep(_entry)

      delete entry.boardPackage
      delete entry.createdDate
      delete entry.deletedDate
      delete entry.layoutAvailability
      delete entry.layoutDocumentSource
      delete entry.modifiedDate

      return new Promise(async resolve => {
        await updateBoardPackageDocument({
          json: entry
        }).then(({ successful, message }) => {
          if (!successful) {
            throw new Error(message)
          }

          resolve()
        })
      })
    })

    await Promise.all(promises)

    this.savingTimeout = setTimeout(() => {
      this.status = 'saved'
    }, 300)
  },

  onSort,
  parseList,

  async getList() {
    const { list, successful, message } = await listBoardPackageDocuments({
      params: {
        hoaID: this.hoaId,
        boardPackageID: this.boardPackageID
      }
    })
    if (!successful) {
      notifyError(message)
      return
    }

    this.list = list
  },

  async erase({ boardPackageDocumentID }) {
    await eraseBoardPackageDocument({
      boardPackageDocumentID
    }).then(({ successful, message }) => {
      if (!successful) {
        throw message
      }
    })

    this.refresh()
  },

  afterUpload() {
    this.loading = true
    this.refresh()
  },

  openEditModal({ boardPackageDocumentID, documentName, spot }) {
    this.$refs.modal.open({
      mode: 'edit',
      //
      boardPackageID: this.boardPackageID,
      sources: this.sources,
      //
      boardPackageDocumentID,
      documentName,
      spot,
      //
      rows: this.rows
    })
  },

  openAddModal() {
    this.$refs.modal.open({
      mode: 'add',
      //
      boardPackageID: this.boardPackageID,
      sources: this.sources,
      list: this.list,
      //
      rows: this.rows
    })
  }
}
