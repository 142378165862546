/*
  Summary:
    // get the data,
    // so that the data can be modified & reuploaded...
    [get] /BoardMeetings/BoardPackageDocument
  
    // if the document is updated,
    // the new document is overwritten
    [post] /BoardMeetings/BoardPackageDocument/StoreBoardPackageDocumentFile {
      boardPackageDocumentID
    }
    
    [put] /BoardMeetings/BoardPackageDocument {
      
    }
*/

import { getBoardPackageDocument } from '@/services/BoardMeetings/BoardPackageDocument/Get'
import { updateBoardPackageDocument } from '@/services/BoardMeetings/BoardPackageDocument/Put'
import { storeBPD } from '@/services/BoardMeetings/BoardPackageDocument/StoreBoardPackageDocumentFile'

import { notifyError } from '@/services/notify'

export async function edit() {
  this.loading = true

  const { documentDescription, documents, boardPackageDocumentID } = this

  const { boardPackageDocument } = await getBoardPackageDocument({
    boardPackageDocumentID
  }).then(({ successful, message, result }) => {
    if (!successful) {
      notifyError(message)
      throw new Error(message)
    }

    return {
      boardPackageDocument: result
    }
  })

  if (this.documentUpdateOccurrred) {
    const { documentID, successful, message } = await storeBPD({
      doc: documents[0],
      params: {
        boardPackageDocumentID
      }
    })
    if (!successful) {
      this.loading = false
      throw new Error(message)
    }

    boardPackageDocument.documentID = documentID
  }

  delete boardPackageDocument.boardPackage
  boardPackageDocument.documentName = documentDescription

  await updateBoardPackageDocument({
    json: boardPackageDocument
  }).then(({ successful, message, boardPackageDocument }) => {
    if (!successful) {
      throw message
    }

    return boardPackageDocument
  })

  this.afterUpload()

  this.loading = false
  this.toggle = false
}
