export function onSort() {
  const component = this
  const { sortable } = this

  var order = sortable.toArray()
  for (let a = 0; a < order.length; a++) {
    const boardPackageDocumentID = parseInt(order[a])

    const entry = component.list.find(entry => {
      return entry.boardPackageDocumentID === boardPackageDocumentID
    })

    entry.sortOrder = a
  }

  component.update()
}
