/*
	import { updateBoardPackageDocument } from '@/services/BoardMeetings/BoardPackageDocument/Put';

  const boardPackageDocument = await addBoardPackageDocument ({
    json: {
      "hoaID": hoaID,
      boardPackageID: 45,

      // "documentID": 0,
      "layoutDocumentSourceID": 2,
      "layoutAvailabilityID": 1,
      // "sortOrder": 0,
      // "documentName": "string",

      "documentName": "name of the document",  
      
    }
  }).then (({ successful, message, boardPackageDocument }) => {
    if (!successful) {
      throw message;
    }

    return boardPackageDocument
  });
*/

/*
  exception.fields {
    phoneNumber: [ '' ],    
  }
*/
//
//
//
import kms from '@/services/kms'
//
import { notifyError } from '@/services/notify'
//
export async function updateBoardPackageDocument({ json }) {
  let exception = ''

  try {
    const result = await kms.put(`/BoardMeetings/BoardPackageDocument`, json)

    return {
      successful: true,
      message: 'The board meeting document was added successfully.',
      exception: null,
      boardPackageDocument: result
    }
  } catch (_exception) {
    exception = _exception
  }

  let message = 'The board meeting document could not be added.'
  notifyError(message)

  return {
    successful: false,
    message,
    exception,
    boardPackageDocument: null
  }
}
