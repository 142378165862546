/*
  import { notifyError } from '@/services/notify'

	import { listBoardPackageDocuments } from '@/services/BoardMeetings/BoardPackageDocument/List';

  const { list, successful, message } = await listBoardPackageDocuments ({
    params: {
			hoaID,
      boardPackageID
    }
  });
  if (!successful) {
    notifyError (message);
    return;
  }
*/

import _get from 'lodash/get'
import kms from '@/services/kms'

export async function listBoardPackageDocuments({ params }) {
  let message = 'The board meetings list could not be retrieved.'

  try {
    const result = await kms.get(`/BoardMeetings/BoardPackageDocument/List`, {
      params: {
        hoaID: params.hoaID,
        boardPackageID: params.boardPackageID
      }
    })

    let list = _get(result, 'results', [])
    if (!Array.isArray(list)) {
      list = []
    }

    return {
      successful: true,
      message,
      list
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    list: []
  }
}
