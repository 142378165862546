import _get from 'lodash/get'

export function data() {
  return {
    loading: false,
    boardPackageID: parseInt(_get(this, ['$route', 'params', 'id'], '')),

    // saving, saved
    status: 'saved',
    saving: true,
    savingTimeout: null,

    rows: [],
    list: [],
    columns: [
      {
        field: 'documentName',
        label: 'Document Name'
      },
      {
        field: 'documentSource',
        label: 'Document Source'
      },
      {
        field: 'available',
        label: 'Available'
      },
      {
        field: 'edit',
        label: ''
      },
      {
        field: 'delete',
        label: ''
      }
    ]
  }
}
