<template>
  <Modal
    :toggle.sync="toggle"
    :loading="loading"
    :styles="{ modalContent: { minHeight: '100px' } }"
  >
    <template v-slot:header v-if="mode === 'add'">Add Board Package Document</template>
    <template v-slot:header v-else>Edit Board Package Document</template>

    <b-field label="Document Type">
      <b-select v-model="documentType">
        <option v-for="(option, index) in sources" :key="index" :value="option">
          {{ option.source }}
        </option>
      </b-select>
    </b-field>

    <b-field label="Document Description">
      <b-input v-model="documentDescription"></b-input>
    </b-field>

    <div v-if="_get(documentType, 'layoutDocumentSourceID', null) === 3">
      <div v-if="mode === 'add'">
        <PDFUploader :updateV2="documentsUpdated" :multiple="false" />
      </div>

      <div v-if="mode === 'edit'">
        <PDFDisplay
          ref="pdfDisplay"
          :style="{
            maxWidth: '800px',
            height: '640px',
            position: 'relative',
            margin: '0 auto'
          }"
        />

        <PDFUploader :updateV2="documentsUpdated" :multiple="false" />
      </div>
    </div>

    <template v-slot:footer>
      <b-button class="is-primary" @click="toggle = false">Close</b-button>
      <b-button class="is-primary" @click="upload" :style="{ float: 'right' }" v-if="mode === 'add'"
        >Upload</b-button
      >
      <b-button class="is-primary" @click="edit" :style="{ float: 'right' }" v-if="mode === 'edit'"
        >Update</b-button
      >
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import PDFUploader from '@/components/Fields/PDFUploader'
import PDFDisplay from '@/components/PDF/Display'

import { methods } from './parts/methods'
import { data } from './parts/data'
import { computed } from './parts/computed'

export default {
  components: {
    Modal,
    PDFUploader,
    PDFDisplay
  },
  props: {
    afterUpload: Function
  },
  computed,
  data,
  methods
}
</script>
