import { mapState } from 'vuex'

export const computed = {
  ...mapState({
    breakpoint: state => state.win.breakpoint,
    hoaId: state => state.user.hoaId
  })
}

//
