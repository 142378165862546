/*
  Summary:
    // metadata creation for board package documents
    [post] /BoardMeetings/BoardPackageDocument {
      boardPackageID
    }
      returns {
        boardPackageDocumentID
      }

    // attaches a file to metadata created in the previous step
    [post] /BoardMeetings/BoardPackageDocument/StoreBoardPackageDocumentFile {
      boardPackageDocumentID
    }
*/

import { addBoardPackageDocument } from '@/services/BoardMeetings/BoardPackageDocument/Post'
import { storeBPD } from '@/services/BoardMeetings/BoardPackageDocument/StoreBoardPackageDocumentFile'

export async function upload() {
  this.loading = true

  const { documentDescription, documentType, documents, boardPackageID, hoaId } = this
  const { layoutDocumentSourceID } = documentType

  const boardPackageDocument = await addBoardPackageDocument({
    json: {
      hoaID: hoaId,
      boardPackageID,
      //
      layoutDocumentSourceID,
      layoutAvailabilityID: 1,

      documentName: documentDescription
    }
  }).then(({ successful, message, boardPackageDocument }) => {
    if (!successful) {
      this.loading = false
      throw message
    }

    return boardPackageDocument
  })

  if (layoutDocumentSourceID === 3) {
    const { boardPackageDocumentID } = boardPackageDocument

    const { successful, message } = await storeBPD({
      doc: documents[0],
      params: {
        boardPackageDocumentID
      }
    })
    if (!successful) {
      this.loading = false
      throw new Error(message)
    }
  }

  this.afterUpload()

  this.loading = false
  this.toggle = false
}
