/*
  example:
    import { getBPD } from '@/services/BoardMeetings/BoardPackageDocument/GetBoardPackageDocumentFile';

    const { doc, successful, message } = await getBPD ({
      params: {
        boardPackageDocumentID: '',
        asBase64: true
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
import { notifyError } from '@/services/notify'
//
export async function getBPD({ params }) {
  try {
    const result = await kms.get(
      `/BoardMeetings/BoardPackageDocument/GetBoardPackageDocumentFile`,
      {
        params
      }
    )

    return {
      successful: true,
      message: '',
      file: result
    }
  } catch (exception) {
    console.error(exception)
  }

  let message = `The board package document file could not be retrieved.`
  notifyError(message)

  return {
    successful: false,
    message,
    doc: null
  }
}

///////
