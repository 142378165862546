import _get from 'lodash/get'
import Up from '../../../components/Up'
import Down from '../../../components/Down'
import BuefyButton from '@/components/buttons/Buefy'

export async function parseList() {
  const component = this
  const { sortable } = this

  this.rows = []
  this.loading = true

  await new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 100)
  })

  this.loading = false

  this.rows = this.list
    .sort((a, b) => {
      return a.sortOrder > b.sortOrder
    })
    .map(spot => {
      const documentName = _get(spot, 'documentName', '')
      const boardPackageDocumentID = _get(spot, 'boardPackageDocumentID', '')

      const layoutDocumentSource = _get(spot, ['layoutDocumentSource', 'source'], '')
      const layoutDocumentSourceID = _get(
        spot,
        ['layoutDocumentSource', 'layoutDocumentSourceID'],
        ''
      )

      const isDocument = layoutDocumentSourceID === 3

      return {
        _dataId: boardPackageDocumentID,
        documentName,
        layoutDocumentSource,
        available: false,
        moveUp: {
          component: Up,
          props: {
            click({ button }) {
              var order = sortable.toArray()

              for (let a = 0; a < order.length; a++) {
                if (order[a] === boardPackageDocumentID.toString()) {
                  const item = order.splice(a, 1)[0]
                  order.splice(a - 1, 0, item)
                  break
                }
              }

              sortable.sort(order)
              component.onSort()

              setTimeout(() => {
                try {
                  button.focus()
                } catch (x) {
                  console.error(x)
                }
              }, 1000)
            }
          }
        },
        moveDown: {
          component: Down,
          props: {
            click({ button }) {
              var order = sortable.toArray()

              for (let a = 0; a < order.length; a++) {
                if (order[a] === boardPackageDocumentID.toString()) {
                  const item = order.splice(a, 1)[0]
                  order.splice(a + 1, 0, item)
                  break
                }
              }

              sortable.sort(order)
              component.onSort()

              setTimeout(() => {
                try {
                  button.focus()
                } catch (x) {
                  console.error(x)
                }
              }, 1000)
            }
          }
        },
        ...(isDocument
          ? {
              edit: {
                component: BuefyButton,
                props: {
                  text: 'edit',
                  click() {
                    component.openEditModal({
                      spot,
                      documentName,
                      boardPackageDocumentID
                    })
                  }
                }
              }
            }
          : {
              edit: ''
            }),
        delete: {
          component: BuefyButton,
          props: {
            text: 'delete',
            type: 'is-danger',
            click() {
              component.$buefy.dialog.confirm({
                title: 'Deleting Document',
                message: `Are you sure you want to <b>delete</b> document "${documentName}"? This action cannot be undone.`,
                confirmText: 'Delete Document',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                  component.loading = true
                  component.erase({
                    boardPackageDocumentID
                  })
                }
              })
            }
          }
        }
      }
    })

  this.$forceUpdate()

  await new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 500)
  })

  const element = this.$refs.table.$el.querySelectorAll('tbody')[0]
  element.querySelectorAll('tr').forEach(tr => {
    /* eslint-disable no-empty */
    try {
      tr.style.cursor = 'move'
    } catch (x) {}
  })
}
