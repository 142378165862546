/*
  /BoardMeetings/BoardPackageDocument/{boardPackageDocumentID}
  
  import { getBoardPackageDocument } from '@/services/BoardMeetings/BoardPackageDocument/Get';

  const { result, successful, message } = await getBoardPackageDocument ({
		boardPackageDocumentID
  });
  if (!successful) {
    notifyError (message);
    return;
  }
*/

import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function getBoardPackageDocument({ boardPackageDocumentID }) {
  try {
    const result = await kms.get(`/BoardMeetings/BoardPackageDocument/${boardPackageDocumentID}`)

    return {
      successful: true,
      message: `The board package document was retrieved successfully.`,
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  const message = `The ${boardPackageDocumentID} could not be retrieved.  It might have been deleted or you might not have permission to open it.`
  notifyError(message)

  return {
    successful: false,
    message,
    result: null
  }
}
