/*
	import { listLayoutDocumentSources } from '@/services/BoardMeetings/LayoutDocumentSource/List';

  const { sources, successful, message } = await listLayoutDocumentSources ({
    params: {
      hoaID
    }
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import _get from 'lodash/get'
import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function listLayoutDocumentSources({ params }) {
  try {
    const result = await kms.get(`/BoardMeetings/LayoutDocumentSource/List`, {
      params: {
        hoaID: params.hoaID
      }
    })

    let list = _get(result, 'results', [])
    if (!Array.isArray(list)) {
      list = []
    }

    return {
      successful: true,
      message: '',
      sources: list
    }
  } catch (exception) {
    console.error(exception)
  }

  let message = 'The document source layouts could not be retrieved.'
  notifyError(message)

  return {
    successful: false,
    message,
    sources: []
  }
}
