<template>
  <div role="main" data-id="meeting documents">
    <div :style="{ padding: '8px' }">
      <b-button class="is-primary is-rounded" @click="openAddModal">Add Document</b-button>
      <span :style="{ display: 'inline-block', width: '10px' }" />
      <b-button class="is-primary is-rounded" @click="preview">Preview Document</b-button>

      <div
        :style="{
          float: 'right',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around'
        }"
      >
        <div
          :style="{
            position: 'relative',
            paddingRight: '6px'
          }"
        >
          <b-icon
            icon="loading"
            size="is-small"
            :style="{
              animation: 'spinAround .5s infinite linear',
              opacity: status === 'saving' ? 1 : 0
            }"
          />
        </div>
        <div
          v-if="status === 'saved'"
          :style="{
            background: '#ddd',
            padding: '2px 0px',
            width: '60px',
            textAlign: 'center',
            borderRadius: '4px'
          }"
        >
          saved
        </div>
        <div
          v-if="status === 'saving'"
          :style="{
            background: '#ddd',
            padding: '2px 0px',
            width: '60px',
            textAlign: 'center',
            borderRadius: '4px'
          }"
        >
          saving
        </div>
      </div>
    </div>

    <ModernTable
      ref="table"
      :columns="columns"
      :rows="rows"
      :filters="{
        show: false
      }"
      :loading="loading"
    />

    <Modal ref="modal" :afterUpload="afterUpload" />
  </div>
</template>

<script>
import ModernTable from '@/components/tables/Modern/Table.vue'

import Modal from './components/Modal'

import Sortable from 'sortablejs'

import { methods } from './parts/methods'
import { data } from './parts/data'
import { computed } from './parts/computed'

export default {
  components: {
    ModernTable,
    Modal
  },
  methods,
  computed,
  data,
  mounted() {
    const component = this

    this.refresh()

    /*
      sort by:
        board package document id
        sort order
    */
    const element = this.$refs.table.$el.querySelectorAll('tbody')[0]
    this.sortable = Sortable.create(element, {
      onSort: function(event) {
        component.onSort({ event })
      }
    })
  }
}
</script>
