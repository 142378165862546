/*
  example:
    import { renderBPD } from '@/services/BoardMeetings/BoardPackage/RenderBoardPackageDocument';

    const { sequence, successful, message } = await renderBPD ({
      params: {
        boardPackageID: '',
        mergeFileName: '',
        asBase64: true
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
import { notifyError } from '@/services/notify'
//
export async function renderBPD({ params }) {
  try {
    const _params = Object.keys(params)
      .map(key => {
        return key + '=' + params[key]
      })
      .join('&')

    const result = await kms.post(
      `/BoardMeetings/BoardPackage/RenderBoardPackageDocument?${_params}`
    )

    return {
      successful: true,
      message: '',
      sequence: result
    }
  } catch (exception) {
    console.error(exception)
  }

  let message = `The minutes file could not be retrieved.`
  notifyError(message)

  return {
    successful: false,
    message,
    sequence: null
  }
}

///////
