//

import { getBPD } from '@/services/BoardMeetings/BoardPackageDocument/GetBoardPackageDocumentFile'

import { upload } from './list/upload'
import { open } from './list/open'
import { edit } from './list/edit'

import _get from 'lodash/get'

export const methods = {
  _get,

  documentsUpdated({ documents }) {
    this.documents = documents
    this.documentUpdateOccurrred = true
  },

  edit,
  upload,

  async getDocument({ boardPackageDocumentID }) {
    const { file, successful, message } = await getBPD({
      params: {
        boardPackageDocumentID,
        asBase64: true
      }
    })
    if (!successful) {
      console.error(message)
      return
    }

    this.documents = [file]
  },

  open
}
