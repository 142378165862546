export function data() {
  return {
    toggle: false,
    loading: false,
    mode: 'add',
    //
    sources: [],
    //
    documentType: {
      layoutDocumentSourceID: 3
    },
    documentDescription: '',
    //
    documentUpdateOccurrred: false,
    documents: []
    //
  }
}
