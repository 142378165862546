<template>
  <b-button
    @click="click({ button: $refs.button.$el })"
    aria-label="move document back one"
    ref="button"
    :rounded="true"
  >
    <div style="height: 20px; width: 20px; background-size: contain;" class="icon-downarrow"></div>
  </b-button>
</template>

<script>
export default {
  props: {
    click: Function
  }
}
</script>
